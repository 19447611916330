@import "static/styles/breakpoints";


// @include xs {
// }

// @include sm {
// }

// @include md {
// }

// @include lg {
// }

// @include xl {
// }

div {
  color: #363636;

  &.hero {
    height: 480px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    @include sm {
      height: 410px;
    }

    @include xs {
      height: 400px;
    }

    &.landing {
      display: flex;
      justify-content: center;
      background: #F7F7F7;
      position: relative;
      overflow: hidden;
      z-index: 1;

      @include sm {
        background: #F7F7F7;
      }
      
      @include xs {
        background: #F7F7F7;
      }

      > .animatedBackground {
        animation: basic 1s 0.5s cubic-bezier(.23,.21,.65,.67) forwards;
        background: linear-gradient(110deg, rgba(2, 80, 145, 0.8) 42%, #F8F8F8C9 42.1%), url("/static/images/hero_0.jpg");
        position: absolute;
        z-index: 2;
        height: 100%;
        width: 100%;
        opacity: 0;
        top: 0;

        @include sm {
          background-size: cover;
          background-position: center;
          animation: mobile 1s 0.5s cubic-bezier(.23,.21,.65,.67) forwards;
          background-image: linear-gradient(180deg, rgba(2, 80, 145, 0.8) 50%, #F8F8F8C9 50%), url("/static/images/hero_0.jpg");
        }
        
        @include xs {
          background-size: cover;
          background-position: center;
          animation: mobile 1s 0.5s cubic-bezier(.23,.21,.65,.67) forwards;
          background-image: linear-gradient(180deg, rgba(2, 80, 145, 0.8) 50%, #F8F8F8C9 50%), url("/static/images/hero_0.jpg");
        }


      }
    }

    .hero-wrapper {
      max-width: 80%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 3;

      @include lg {
        max-width: 90%;
      }

      @include md {
        max-width: 90%;
      }

      @include sm {
        flex-direction: column;
        max-width: 95%;
        height: 100%;
        align-items: center;
        justify-content: start;
      }

      @include xs {
        flex-direction: column;
        max-width: 95%;
        height: 100%;
        align-items: center;
        justify-content: start;
      }

      .hero-logo {
        width: 35%;

        @include sm {
          width: 60%;
          margin: 4rem 0;
        }

        @include xs {
          width: 80%;
          margin: 4rem 0;
        }

        img {
          width: 100%;
        }
      }

      .hero-message {
        width: 50%;

        @include sm {
          width: 100%;
        }

        @include xs {
          width: 100%;
        }

        p {
          text-align: center;

          &.title {
            font-size: 40px;
            font-weight: 700;
            color: #363636;

            @include lg {
              font-size: 35px;
            }
      
            @include md {
              font-size: 25px;
            }
      
            @include sm {
              font-size: 20px;
            }
            
            @include xs {
              font-size: 20px;
            }

          }
          &.subtitle {
            font-size: 24px;

            @include lg {
              font-size: 20px;
            }
      
            @include md {
              font-size: 16px;
            }
      
            @include sm {
              font-size: 14px;
            }

            @include xs {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  .our-mission-vision {
    display: flex;
    justify-content: center;
    padding: 3rem 0;

    .wrapper {
      width: 80%;
      display: flex;
      justify-content: space-between;

      @include md {
        width: 95%;
        gap: 40px;
      }

      @include sm {
        width: 80%;
        flex-direction: column;
        align-items: center;

      }

      @include xs {
        width: 98%;
        flex-direction: column;
        align-items: center;
      }

      div {
        margin: 0 1rem;

        @include md {
          margin: 0;
        }

        &.initial {
          width: 25%;
          padding-right: 3rem;

          @include md {
            padding: 0;
          }

          @include sm {
            width: 100%;
            padding: 0;
            text-align: center;
            margin: 0;
          }
          
          @include xs {
            width: 100%;
            padding: 0;
            text-align: center;
            margin: 0;
          }


          .button-container {
            padding: 2rem 0;
            margin: 0;
          }

          p {
            line-height: 25px;

            &.title {
              font-size: 32px;
              font-weight: 600;

              @include md {
                font-size: 26px;
              }
            }
          }

          a {
            display: inline-block;
            color: white;
            text-decoration: none;
            background-color: #485fc7;
            padding: 0.8rem 1.5rem;
            border-radius: 5px;
            font-size: 16px;
            position: relative;
            overflow: hidden;
            
            &:after {
              content: '';
              position: absolute;
              bottom: 0px;
              left: 0px;
              width: 100%;
              height: 100%;
              transform: translateX(-100%);
              background-color: #3E56C4;
              color: white;
              border-radius: 5px;
              transition: transform 0.3s;
            }

            &:hover {
              &:after {
                  transform: translateX(0);
              }
            }

            p {
              position: relative;
              margin: 0;
              z-index: 10;

              @include md {
                font-size: 16px;
                line-height: 20px;
                text-align: center;
                font-weight: 600;
              }

            }

          }
          // a:hover {
          //   color: #fff;
          //   box-shadow: inset  0 0 0 #54b3d6;;
          // }
        }

        &.card {
          width: 25%;
          padding: 1rem 2rem;
          background-color: white;
          border-radius: 5px;
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.06);

          @include sm {
            margin: 0;
            margin-bottom: 2rem;
            padding: 1rem 2rem;
            width: 100%;
          }

          @include xs {
            margin: 0;
            margin-bottom: 2rem;
            width: 90%;
            padding: 1rem 1rem;
          }

          p {
            line-height: 25px;

            &.title {
              font-size: 24px;
              font-weight: 600;
            }
          }

          hr {
            border: 1px solid #f5f5f5;
          }

        }
      }
    }
  }

  .highlight-banner {
    color: white;
    padding: 4rem 0;
    display: flex;
    justify-content: center;

    .wrapper {
      color: white;
      width: 35%;
      display: flex;
      flex-direction: column;
      text-align: justify;
      z-index: 3;

      @include lg {
        width: 50%;
        text-align: justify;
      }

      @include md {
        width: 60%;
        text-align: justify;
      }

      @include sm {
        width: 80%;
        text-align: center;
      }
      
      @include xs {
        width: 90%;
        text-align: center;
      }

    }

    &.quality-politics {
      position: relative;
      background-color: #0B5491;
      z-index: 1;
      overflow: hidden;

      > .animatedBackground {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 2;
        top: 0;

        animation: basic 1s 0.3s cubic-bezier(.23,.21,.65,.67) forwards;
        background: linear-gradient(150deg, rgba(2, 80, 145, 0.9) 50%, rgba(113, 197, 238, 0.9)), url("/static/images/instalaciones_1.jpg");
        background-size: cover;
        background-position: center;
        opacity: 0;

        @include sm {
          animation: mobile 1s 0.3s cubic-bezier(.23,.21,.65,.67) forwards;
          background-size: cover;
        }

        @include xs {
          animation: mobile 1s 0.3s cubic-bezier(.23,.21,.65,.67) forwards;
          background-size: cover;
        }
      }
    }

    p {
      &.title {
        text-align: center;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.125;
      }
    }
  }

  .team {
    display: flex;
    justify-content: center;
    padding: 4rem 0;

    .wrapper {
      display: flex;
      flex-direction: column;
      width: 70%;
      align-items: center;

      @include lg {
        width: 85%;
      }

      @include md {
        width: 97%;
      }
      
      @include sm {
        width: 90%;
      }

      @include xs {
        width: 95%;
      } 
    }

    .title {
      p {
        text-align: center;
        color: #363636;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.125;
      }
    }

    .card-main {
      display: flex;
      background-color: #fff;
      border: 1px solid #e0e0e0;
      border-radius: 5px;
      box-shadow: 17px 22px 15px -3px rgba(0,0,0,0.1);
      padding: 2rem 5rem;

      @include sm {
        flex-direction: column;
        padding: 0;
      }

      @include xs {
        flex-direction: column;
        padding: 0;
      }

      .main-picture {
        width: 35%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;

        @include lg {
          width: 40%;
        }
        
        @include md {
          width: 50%;
        }

        @include sm {
          width: 70%;
          margin: auto;
          padding: 2rem 0;
        }

        @include xs {
          width: 70%;
          margin: auto;
          padding: 2rem 0;
        }

        img {
          width: 100%;
          border-radius: 25px;
        }
      }

      .main-info {
        // width: 45%;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: end;
        padding-left: 3rem;

        @include sm {
          padding: 1.5rem 2rem;
        }

        @include xs {
          padding: 1.5rem 2rem;
        }

        .info-header {
          p:first-child {
            font-size: 24px;
            font-weight: 400;
            line-height: 1.25;
            margin: 0;
            text-align: left;
          }

          p {
            margin: 0;
          }
        }
      }
    }

    .small-cards {
      display: flex;
      justify-content: space-between;
      padding: 3rem 0rem;

      @include sm {
        flex-direction: column;
      }

      @include xs {
        flex-direction: column;
      }

      .card {
        background-color: #fff;
        border: 1px solid #e0e0e0;
        border-radius: 5px;
        box-shadow: 17px 22px 15px -3px rgba(0,0,0,0.1);
        width: 30%;
        padding: 0 0.1rem 1rem 0.1rem;
        text-align: center;

        @include sm {
          width: 100%;
          margin-bottom: 2rem;
          padding-bottom: 2em;
        }

        @include xs {
          width: 100%;
          margin-bottom: 2rem;
          padding-left: 0;
          padding-right: 0;
          padding-bottom: 2em;
        }

        p:first-child {
          font-size: 20px;
        }

        img {
          width: 70%;
          border-bottom-left-radius: 25px;
          border-bottom-right-radius: 25px;
        }

        p {
          margin: 0;
          font-size: 16px;

          &:first-of-type {
            font-weight: 600;
            padding-top: 1rem;
            font-size: 18px;
          }

        }
      }

      .cta {
        padding: 3rem 0;
        a {
          color: black;
        }
      }
    }

    .cta {
      a {
        display: inline-block;
        color: white;
        text-decoration: none;
        background-color: #485fc7;
        padding: 0.8rem 1.5rem;
        border-radius: 5px;
        font-size: 16px;
        position: relative;
        overflow: hidden;
        cursor: pointer;

        &:after {
          content: '';
          position: absolute;
          bottom: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          transform: translateX(-100%);
          background-color: #3E56C4;
          color: white;
          border-radius: 5px;
          transition: transform 0.3s;
        }

        &:hover {
          &:after {
              transform: translateX(0);
          }
        }

        p {
          position: relative;
          margin: 0;
          z-index: 10;
        }
      }
    }
  }

  .testimonies {
    display: flex;
    justify-content: center;
    position: relative;
    background: #3D6287;
    z-index: 1;

    > .animatedBackground {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 2;

      animation: basic 1s 0.3s cubic-bezier(.23,.21,.65,.67) forwards;
      background: linear-gradient(152deg, rgba(112,159,199,0.7) 3%, rgba(6,62,111,0.85) 36%, rgba(1,58,107,0.9) 62%, rgba(10,59,103,0.9) 64%, rgba(137,140,210,0.85) 100%), url("/static/images/hero_1.jpg");
      background-position: center;
      background-size: cover;
      opacity: 0;

      @include sm {
        animation: mobile 1s 0.3s cubic-bezier(.23,.21,.65,.67) forwards;
      }

      @include xs {
        animation: mobile 1s 0.3s cubic-bezier(.23,.21,.65,.67) forwards;
      }
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      width: 45%;
      text-align: center;
      color: white;
      padding: 5rem 0;
      z-index: 3;

      @include md {
        width: 60%;
      }

      @include sm {
        width: 80%;
      }

      @include xs {
        width: 90%;
      }

      p:first-child {
        font-size: 32px;
        font-weight: 600;
      }

      p:last-child {
        text-align: right;
      }
    }
  }

  .values {
    display: flex;
    justify-content: center;

    .wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      width: 70%;
      padding: 4rem 0;

      @include md {
        width: 80%;
      }

      @include sm {
        width: 90%;
      }

      @include xs {
        width: 98%;
      }

      p {
        font-size: 32px;
        font-weight: 600;
      }
    }
  }

  .location {
    display: flex;
    justify-content: center;
    position: relative;
    background: #3986B8;
    z-index: 1;

    > .animatedBackground {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      z-index: 2;

      animation: basic 1s 0.3s cubic-bezier(.23,.21,.65,.67) forwards;
      background: linear-gradient(150deg,rgba(2,80,145,.9) 50%,rgba(113,197,238,.9)),url("/static/images/instalaciones_8.jpg");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      opacity: 0;

      @include sm {
        animation: mobile 1s 0.3s cubic-bezier(.23,.21,.65,.67) forwards;
      }

      @include xs {
        animation: mobile 1s 0.3s cubic-bezier(.23,.21,.65,.67) forwards;
      }

    }

    .wrapper {
      width: 70%;
      display: flex;
      flex-direction: column;
      text-align: center;
      color: white;
      padding: 4rem 0;
      z-index: 3;

      @include lg {
        width: 85%;
      }

      @include md {
        width: 80%;
      }

      @include sm {
        width: 90%;
      }

      @include xs {
        width: 95%;
      }
      

      > p {
        font-size: 32px;
        margin-top: 0;
        font-weight: 600;
      }

      .columns {
        display: flex;
        justify-content: space-around;
        padding-top: 2rem;

        @include md {
          flex-direction: column;
        }

        @include sm {
          flex-direction: column;
        }

        @include xs {
          flex-direction: column;
        }

        .map {
          width: 50%;

          @include md {
            width: 100%;
            height: 400px;
            padding-bottom: 3rem;
          }

          @include sm {
            width: 100%;
            height: 400px;
            padding-bottom: 3rem;
          }

          @include xs {
            width: 100%;
            height: 400px;
            padding-bottom: 3rem;
          }
        }

        .info {
          width: 40%;

          @include md {
            width: 100%;
          }

          @include sm {
            width: 100%;
          }

          @include xs {
            width: 100%;
          }

          p {
            margin-top: 0;
            color: white;

            &.title {
              font-size: 20px;
              font-weight: 600;
              margin-bottom: 0.3rem;
            }

            &.phone {
              margin-bottom: 0.2rem;
            }

            span {
              font-weight: 600;
            }

          }
        }

        iframe {
          border-radius: 15px;
          border: 0;
          width: 100%;
          height: 100%;
        }

        p {
          text-align: left;
        }
      }
    }
  }

  .partners {
    display: flex;
    justify-content: center;

    .wrapper {
      padding: 5rem 0;
      text-align: center;
      display: flex;
      flex-direction: column;
      width: 80%;
      align-items: center;

      @include md {
        width: 80%;
      }

      @include sm {
        width: 90%;
      }

      @include xs {
        width: 98%;
      }

       > p {
         font-size: 32px;
         margin-top: 0;
         font-weight: 600;
       }

       img {
         padding: 3rem 0;
         width: 35%;

         @include lg {
          width: 45%;
        }

        @include md {
          width: 55%;
        }
  
        @include sm {
          width: 65%;
        }
  
        @include xs {
          width: 75%;
        }


       }
    }
  }
}

/* Animaciones */
@keyframes basic{
  0% {
    opacity: 0;
    transform: translateX(-2rem);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes mobile{
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
