@import "static/styles/breakpoints";

nav {
  display: flex;
  justify-content: center;
  background-color: rgb(2, 80, 145);
  padding: 0.5rem 0;
  width: 100%;
  z-index: 100;
}

div {
  .wrapper {
    width: 80%;
    display: flex;

    @include sm {
      width: 90%;
    }

    @include xs {
      width: 95%;
    }


    .logo {
      width: 15%;

      @include sm {
        width: 45%;
        display: flex;
        align-items: center;
      }
  
      @include xs {
        width: 45%;
        display: flex;
        align-items: center;
      }

      a {
        width: 100%;
        img {
          width: 100%;
        }
      }

    }

    .elements {
      padding-left: 1rem;
      display: flex;
      column-gap: 150px;
      align-items: center;
      flex: 1;

      .pages {
        display: flex;
        column-gap: 15px;
        font-size: 18px;

        @include sm {
          flex-direction: column;
          align-items: flex-end;
          width: 100%;

        }

        @include xs {
          flex-direction: column;
          align-items: flex-end;
          width: 100%;
          font-size: 14px;
          font-weight: 600;
        }

        a {
          text-decoration: none;
          padding: 0.5rem 1rem;
          overflow: hidden;
          position: relative;
          color: white;
          font-weight: 500;

          &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 3px;
            transform: scaleX(0);
            background-color: white;
            transition: transform 0.3s;
          }

          &:hover {
            &:after {
                transform: scaleX(1);
            }
          }
        }

      }
    }
  }
}
