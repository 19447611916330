@import "static/styles/breakpoints";

.container {
    display: flex;
    justify-content: center;
    padding: 4rem 0;
    background-color: #F8F8F8;

    > .wrapper {
        width: 70%;

        @include lg {
            width: 85%;
        }

        @include md {
            width: 85%;
        }

        @include sm {
            width: 90%;
        }

        @include xs {
            width: 95%;
        }

        > .title {
            text-align: center;
            padding-bottom: 3rem;

            > p {

                font-size: 20px;

                &:first-of-type {
                    font-size: 35px;
                    font-weight: 600;
                }
            }
        }

        > .cards {

            > .card {
                display: flex;
                gap: 50px;
                padding: 3rem;
                border: 1px solid #e0e0e0;
                border-radius: 5px;
                box-shadow: 17px 22px 15px -3px rgb(0 0 0 / 10%);
                margin-bottom: 2rem;
                opacity: 0;

                &.animate {
                    animation: cards 0.7s .3s ease-in forwards;
                }

                @include lg {
                    gap: 35px;
                    align-items: center;
                }

                @include md {
                    gap: 25px;
                    align-items: center;
                }

                @include sm {
                    flex-direction: column;
                    padding: 2rem 1.5rem;
                    
                }

                @include xs {
                    flex-direction: column;
                    padding: 2rem 1.5rem;
                    
                }
    
                > .picture {
                    width: 25%;

                    @include lg {
                        width: 35%;
                    }

                    @include md {
                        width: 45%;
                    }

                    @include sm {
                        width: 100%;
                    }

                    @include xs {
                        width: 100%;
                    }

                    > img {
                        width: 100%;
                        border-radius: 15px;
                    }
    
                }
    
                > .info {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;

                    > hr {
                        background-color: #adadad;
                        width: 100%;
                    }

                    > p {
                        margin: 0;
                        font-size: 20px;
                        font-weight: 500;

                        &:first-of-type {
                            font-size: 30px;
                            font-weight: 600;
                        }
                    }


                    > .social_networks {
                        padding-top: 0.5rem;
                        display: flex;
                        gap: 20px;

                        > a {
                            cursor: pointer;
                            width: 13px;
                            > img {
                                border: 1px solid #000;
                                padding: 0.3rem;
                                border-radius: 5px;
                            }
                        }
                    }
                }
            }


        }
    }
}

/* animaciones */

@keyframes cards {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}