
.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f7f7f7;

    a {
        text-decoration: none;
        color: #3273dc;

        &:visited {
            color: #3273dc;
          }
    }

    .header {
        width: 100%;
        padding: 100px 0;
        background-color: #e69085;
        display: flex;
        align-items: center;

        .logo {
            width: 100%;
            display: flex;
            justify-content: center;
            img {
                width: 300px;
                border-radius: 16px;
            }
        }

    }

    .footer {
        background-color: #fa9c90;
        min-height: 120px;
        width: 100%;
    }
    
    .info {
        .profile_picture_wrapper {
            position: relative;
            display: flex;
            justify-content: center;
            height: 260px;

            .profile_picture {
                position: absolute;
                top: -60px;

                img {
                    width: 300px;
                    height: 300px;
                    object-fit: cover;
                    border-radius: 50%;
                    box-shadow: 17px 22px 15px -3px rgb(0 0 0 / 10%);
                }
            }
        }

        .profile_info {
            padding: 12px 24px;

            hr {
                margin-bottom: 0;
                margin-top: 24px;
            }

            .title {
                display: flex;
                flex-direction: column;
                gap: 8px;
                align-items: center;
                p {
                    margin: 0;
                    font-size: 18px;
                    font-weight: 600;
                    text-align: center;

                    &.name {
                        font-size: 32px;
                        font-weight: 700;
                        text-align: center;
                        line-height: 32px;
                        padding-bottom: 8px;
                    }
                }
            }

            .contact {
                padding: 24px 0 12px 0;
                display: flex;
                flex-direction: column;
                gap: 8px;

                p {
                    margin: 0;

                    &.section {
                        font-size: 22px;
                        font-weight: 600;
                        padding-bottom: 12px;
                    }
                }

                .field {
                    display: flex;
                    align-items: center;
                    gap: 16px;

                    iframe {
                        border-radius: 15px;
                        border: 0;
                        width: 100%;
                        height: 100%;
                    }

                    &.map {
                        padding: 24px 0;
                    }


                    .paragraph {

                        p {
                            &:first-of-type {
                                font-weight: 600;
                                font-size: 18px;
                                padding-bottom: 8px;
                            }

                            &.separation {
                                padding-bottom: 8px;
                            }
                        }
                    }

                    

                }

            }

        }

    }
}