@import "static/styles/breakpoints";

.container {
    display: flex;
    justify-content: center;
    padding: 4rem 0;
    background-color: #F8F8F8;

    > .wrapper {
        width: 80%;

        @include sm {
            width: 70%;
        }

        @include xs {
            width: 90%;
        }

        > .title {
            text-align: center;
            padding-bottom: 3rem;

            > p {
                font-size: 16px;

                &:first-of-type {
                    font-size: 32px;
                    font-weight: 600;
                }

            }
        }

        > .services {

            > .services_row {
                display: flex;
                justify-content: center;
                gap: 48px;
                padding-bottom: 4rem;
                opacity: 0;

                &.animate {
                    animation: services 0.7s .3s ease-in forwards;
                }



                @include sm {
                    flex-direction: column;
                    justify-content: center;
                }

                @include xs {
                    flex-direction: column;
                }

                > .card {
                    display: flex;
                    flex-direction: column;
                    padding: 0 2rem;
                    padding-bottom: 1.5rem;
                    background-color: #FFF;
                    border: 1px solid #e0e0e0;
                    box-shadow: 17px 22px 15px -3px rgb(0 0 0 / 10%);

                    @include xl {
                        width: 20%;
                    }

                    @include lg {
                        width: 20%;
                    }

                    @include md {
                        width: 30%;
                    }

                    @include sm {
                        padding: 0 2rem;
                        padding-bottom: 1rem;
                    }

                    @include xs {
                        padding: 0 2rem;
                        padding-bottom: 1rem;
                    }

                    >.image_container {
                        display: flex;
                        justify-content: center;

                        > .image_spacer {
                            background-color: #f0f0f0;
                            border-radius: 9999px;
                            border-top-left-radius: 0;
                            border-top-right-radius: 0;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 100px;
                            width: 110px;

                            > img {
                                width: 40%;
                            }
                        }
                    }

                    > .definition {
                        text-align: left;
                    }

                    > p {
                        text-align: center;
                        
                        &:first-of-type {
                            font-size: 22px;
                            font-weight: 600;
                            margin-top: 2rem;
                            margin-bottom: 1rem;
                        }
                    }

                    > hr {
                        width: 100%;
                        background-color: #adadad;
                    }
                }
            }
        }

        > .facilities {
            padding: 0 3rem;
            width: 60%;
            margin: auto;
            opacity: 0;

            &.animate {
                animation: services 0.7s .3s ease-in forwards;
            }

            @include md {
                width: 100%;
                padding: 0;
            }

            @include sm {
                width: 100%;
                padding: 0;
            }

            @include xs {
                width: 100%;
                padding: 0;
            }

            img {
                width: 100%;
            }
        }
    }
}

/* animaciones */

@keyframes services {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

