@import "static/styles/breakpoints";

.footer {
  background-color: rgb(2, 80, 145);
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 4rem 0;
  
  p {
    color: #fff;
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 0.5rem;

    &.header {
      font-size: 20px;;
      font-weight: 600;
      margin: 14px 0;
    }
  }

  a {

    &.footer_link {
      cursor: pointer;
      color: #fff;
      text-decoration: none;
      position: relative;
      overflow: visible;
      font-size: 14px;
      margin-bottom: 0.5rem;
      width: fit-content;

      &:after {
        content: '';
        position: absolute;
        bottom: -3px;
        left: 0;
        right: 0;
        width: 100%;
        height: 1px;
        transform: scaleX(0);
        background-color: white;
        transition: transform 0.3s;
      }

      &:hover {
        &:after {
            transform: scaleX(1);

        }
      }
    }
  
  }

  > hr {
    width: 90%;
    margin: 2rem 0;
  }

  > .wrapper {
    width: 70%;
    display: flex;
    justify-content: space-between;

    @include sm {
      flex-direction: column;
      width: 80%;
    }

    @include xs {
      flex-direction: column;
      width: 80%;
    }

    > .text_right {
      text-align: right;
      @include sm {
        text-align: left;
        padding-top: 2rem;
      }
  
      @include xs {
        text-align: left;
        padding-top: 2rem;
      }
    }

    > .primary {
      width: 20%;
      @include sm {
        width: 100%;
      }
  
      @include xs {
        width: 100%;
      }
    } 

    > .secondary {
      display: flex;
      flex-direction: column;

      > .social_networks {
        display: flex;
        gap: 20px;

        >.social_network {
            cursor: pointer;
            width: 15px;

            > img {
                width: 100%;
                border: 1px solid #fff;
                padding: 0.3rem;
                border-radius: 5px;
                background-color: #fff;
            }
        }
      }
    }
  }
}
